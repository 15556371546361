/* Mixin */

.link-arrow(@icon,@h,@w,@top,@margin-top) {
  background: @icon;
  background-repeat: no-repeat;
  content: "";
  height: @h;
  width: @w;
  display: inline-block;
  position: absolute;
  right: 0;
  top: @top;
  margin-top: @margin-top;
}

.header-line(@line-color,@margin-top,@margin-bottom) {
  content: "";
  display: block;
  width: 40px;
  height: 4px;
  margin-top: @margin-top;
  margin-bottom: @margin-bottom;
  background-color: @line-color;
}

// todo: add fontcolor to mixin
.backgroundOnFocusHoverUnderlinedText(@bgColor,@fgColor) {
  body:not(#vrtx-frontpage) p.elm-has-own-text a:not([class*="button"]),
  body:not(#vrtx-frontpage) span.elm-has-own-text a:not([class*="button"]),
  body:not(#vrtx-frontpage) i.elm-has-own-text a:not([class*="button"]),
  body:not(#vrtx-frontpage) em.elm-has-own-text a:not([class*="button"]),
  body:not(#vrtx-frontpage) bold.elm-has-own-text a:not([class*="button"]),
  body:not(#vrtx-frontpage) strong.elm-has-own-text a:not([class*="button"]),
  body:not(#vrtx-frontpage)
    td.elm-has-own-text:not([class*="person-listing"])
    a:not([class*="button"]),
  body:not(#vrtx-frontpage)
    ul:not([class^="vrtx-tags-"])
    li.elm-has-own-text
    > a:not([class*="button"]),
  body:not(#vrtx-frontpage)
    ul:not([class^="vrtx-tags-"])
    li.elm-has-own-text
    > *:not(ul):not(ol)
    a:not([class*="button"]),
  body:not(#vrtx-frontpage)
    ol:not([class^="vrtx-tags-"])
    li.elm-has-own-text
    > a:not([class*="button"]),
  body:not(#vrtx-frontpage)
    ol:not([class^="vrtx-tags-"])
    li.elm-has-own-text
    > *:not(ul):not(ol)
    a:not([class*="button"]) {
    &:focus,
    &:hover,
    &.hover {
      background-color: @bgColor;
      .boxShadow(0 0 0 2px @bgColor);
      color: @fgColor;
    }
  }
}

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

@keyframes fall-down {
  0.00% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -50, 1, 1);
    opacity: 0;
  }
  11.11% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -1.905, 1, 1);
  }
  22.22% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1.922, 1, 1);
  }
  33.33% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.2, 1, 1);
  }
  44.44% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.062, 1, 1);
  }
  55.56% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.013, 1, 1);
  }
  66.67% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.002, 1, 1);
    opacity: 1;
  }
  77.78% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.001, 1, 1);
  }
  88.89% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0, 1, 1);
  }
  100.00% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0, 1, 1);
  }
}

@keyframes pop-in {
  0.00% {
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.55% {
    transform: matrix3d(0.793, 0, 0, 0, 0, 0.793, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.09% {
    transform: matrix3d(0.949, 0, 0, 0, 0, 0.949, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.64% {
    transform: matrix3d(1.008, 0, 0, 0, 0, 1.008, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.18% {
    transform: matrix3d(1.019, 0, 0, 0, 0, 1.019, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  22.73% {
    transform: matrix3d(1.014, 0, 0, 0, 0, 1.014, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  27.27% {
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  31.82% {
    transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  36.36% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  40.91% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  45.45% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  50.00% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  54.55% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  59.09% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.64% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  68.18% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  72.73% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  77.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  81.82% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  86.36% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  90.91% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  95.45% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100.00% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

@keyframes table-expand {
  0.00% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -50, 1, 1);
    opacity: 0;
  }
  11.11% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -1.905, 1, 1);
  }
  22.22% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1.922, 1, 1);
  }
  33.33% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.2, 1, 1);
  }
  44.44% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.062, 1, 1);
  }
  50% {
    width: @pagewidth-extra-wide;
  }
  55.56% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0.013, 1, 1);
  }
  66.67% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.002, 1, 1);
    opacity: 1;
  }
  77.78% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0.001, 1, 1);
  }
  88.89% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -0, 1, 1);
  }
  100% {
    margin-left: -190px;
    width: @pagewidth-extra-wide;
  }
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #45bfb1; // Lys turkis – var #3eb6a7
@color-primary--dark: #005f70; // Mork turkis – var #006c7e
@color-primary--darker: #263f44;
@color-primary--light: tint(@color-primary, 25%);
@color-contrast: #8f4032; //mursteinsrød

// Neutral colors
@color-neutral--dark: #1e2728;
@color-neutral: #263239;
@color-neutral--grey: #dcdbdc;
@color-neutral--light: #e4e4e4;
@color-neutral--air: #f2f2f2;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #222222;
@color-body-font: #4b5253;

// Specific colors.
// Variables are there to be reused, so being to specific or creating to many will create the opposite situation
@color-background: #f7f7f7;
@color-text: @color-dark;
@color-links: @color-primary--dark;
@color-links--hover: #000000;
@color-header: #2b2b2b;

/*------------------------------------*\
    # TYPE
\*------------------------------------*/
// This friendly upright italic is the serif cousin of TypeTogether's award winning family Bree.
// Designed by Veronika Burian and José Scaglione, Bree was originally released in 2008 and became
// an immediate success because of its originality, charming appearance and versatility.
// https://www.fontsquirrel.com/license/bree-serif
@font-face {
  font-family: "Bree Serif";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/breeserif.woff2") format("woff2");
  //src: url(https://fonts.gstatic.com/s/breeserif/v16/4UaHrEJCrhhnVA3DgluA96rp57F2IwM.woff2) format('woff2');
}

// Lora by Cyreal
// https://www.fontsquirrel.com/license/lora
@font-face {
  font-family: "Lora";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/lora-regular-italic.woff2") format("woff2");
  //src: url(https://fonts.gstatic.com/s/lora/v23/0QIhMX1D_JOuMw_LIftLtfOm8w.woff2) format('woff2');
}
@font-face {
  font-family: "Lora";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/lora-bold-italic.woff2") format("woff2");
  //src: url(https://fonts.gstatic.com/s/lora/v23/0QIhMX1D_JOuMw_LIftLtfOm8w.woff2) format('woff2');
}
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/lora-regular.woff2") format("woff2");
  //src: url(https://fonts.gstatic.com/s/lora/v23/0QIvMX1D_JOuMwr7I_FMl_E.woff2) format('woff2');
}
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/lora-bold.woff2") format("woff2");
  //src: url(https://fonts.gstatic.com/s/lora/v23/0QIvMX1D_JOuMwr7I_FMl_E.woff2) format('woff2');
}

@font-header: "Bree Serif";
@font-content: "Lora";

/*------------------------------------*\
    # FONT-SIZES
\*------------------------------------*/
@font-size--xsmall: 16px;
@font-size--small: 18px;
@font-size--medium: 22px;
@font-size--large: 24px;
@font-size--xlarge: 28px;
@font-size--xxlarge: 50px;

/*------------------------------------*\
    # pagewidths
\*------------------------------------*/
@pagewidth-narrow: 680px; //680px includes 20px padding for both sides.
@pagewidth-wide: 970px;
@pagewidth-extra-wide: 1140px;
