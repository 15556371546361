/* CSS for norgeshistorie */
@import "utils.less";

// UU: removing skiplink to unused element
a[href$="\#bottomnav"] {
  display: none;
}

// uio line with logo. NOT IN USE
.uio-app-line-top {
  display: none; // NOT IN USE
  background: @color-neutral;
  height: 44px;
  .uio-logo a {
    background-image: none, url(../images/sprite.svg);
    background-position: 0px -300px;
    width: 195px;
    height: 15px;
    top: 15px;
    &:hover,
    &:focus {
      background-image: none, url(../images/sprite.svg);
      background-position: 0px -300px;
      width: 195px;
      height: 15px;
      border-bottom: 2px solid @color-primary--dark;
    }
  }
}

body {
  .font-size(18);
  -webkit-font-smoothing: antialiased;
  line-height: 1.33;
  // avoiding scrollbar when wide boxes are used eg. authors-wrapper and kilde-wrapper
  overflow-x: hidden;
}

html,
body {
  font-family: @font-content;
  color: @color-body-font;
  font-weight: 400;
  line-height: 1.6;
  background: @color-background;
}

// Link-background on focus/hover
.backgroundOnFocusHoverUnderlinedText(transparent, @color-neutral--dark);
.backgroundOnFocusHoverFooterUnderlinedText(transparent);

#main a {
  cursor: pointer;
  text-decoration: underline;
  color: @color-primary--dark;
  border: 0;
  &:hover,
  &:focus {
    color: @color-neutral--dark;
  }
  &:focus {
    text-decoration: none;
    outline: auto !important;
  }
}

// External links
#vrtx-content,
#vrtx-related-content {
  a[href*="//"]:not([href*="norgeshistorie.no"]):not(button):not(.img-link)
  {
    &:after {
      content: "";
      vertical-align: baseline;
      margin-left: 3px;
      margin-right: 5px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-image: none, url(../images/sprite.svg);
      background-position: -1000px 0px;
      background-repeat: no-repeat;
    }
  }
}

// Lytt-ikon 
//#vrtx-structured-article:has([href*="tag=vrtx-lytt"]) h1,
.vrtx-feed li[data-categories*="tag:vrtx-lytt"] .vrtx-list-item-content {
  &::after {
    content: "Kan lyttes til";
    background: url(../images/headphones.svg) no-repeat;
    background-size: contain;
    height: 21px;
    display: inline-block;
    color: black;
    font-size: 1.4rem;
    font-weight: 700;
    white-space: nowrap;
    padding-left: 30px;
    padding-top: 1px;
  }
}
#vrtx-structured-article {
  // &:has([href*="tag=vrtx-lytt"]) h1::after {
  //   padding-top: 5px;
  //   margin-left: 30px;
  //   margin-top: 20px;
  //   position: absolute;
  // }
  .vrtx-tags-links {
    a[href*="tag=vrtx-lytt"], a[href*="tag=vrtx-lytt"] + .tag-separator {
      display: none;
    }
  }
}
#vrtx-tagview {
  li[class*="vrtx-tags-element"]:has([href*="tag=vrtx-lytt"]) {
    display: none;
  }
}
// Lytt ikon ^

#main {
  #vrtx-daily-events,
  .vrtx-resources {
    .vrtx-daily-events-listing a.vrtx-title,
    .vrtx-resource a.vrtx-title {
      font-family: @font-header;
      font-weight: 400;
      .font-size(24);
      color: @color-neutral--dark;
      text-decoration: underline;
      &:hover,
      &:focus {
        color: @color-primary--dark;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: @font-header;
  font-weight: 400;
  word-wrap: break-word;
  letter-spacing: 0;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 10px;
  color: @color-dark;
}

#vrtx-audio-video-listing #main #total-main {
  width: @pagewidth-narrow;
  margin: 0 auto;
}

.vrtx-frontpage-full-width.total-main #main {
  h1 {
    margin-top: 59px;
    width: @pagewidth-narrow;
  }
}

#vrtx-frontpage h1 {
  margin-top: 59px;
}

#vrtx-structured-article h1 {
  margin-top: 15px;
}

#vrtx-tagview h1 {
  margin-left: auto;
  margin-right: auto;
}

h1 {
  .font-size(50);
  width: 680px;
  margin-top: 50px;
  line-height: 1.1;
  padding-bottom: 20px;
  &::after {
    .header-line(@color-primary, 15px, 0);
  }
}

h2 {
  .font-size(30);
}

h3 {
  .font-size(22);
}

h4 {
  .font-size(18);
}

h5 {
  .font-size(18);
  font-style: italic;
}

p {
  margin-bottom: 20px;
}

// Searchview
#vrtx-searchview {
  h1,
  .vrtx-topuioresult {
    display: none;
  }
  .hits {
    font-family: @font-header;
    font-weight: 400;
    color: @color-neutral--dark;
    .font-size(26);
  }
  .vrtx-search-results-container div.vrtx-search-results {
    .result h2 a {
      color: @color-neutral--dark;
      text-decoration: underline !important;
    }
    .footer {
      display: none;
    }
  }
  #main {
    #total-main {
      margin-bottom: 40px;
      #vrtx-content,
      #vrtx-main-content {
        width: @pagewidth-narrow;
        margin: 0 auto;
        button.searchsubmit,
        .button.submit-button-link {
          background: url(../images/sprite-nh-magnifier.svg) no-repeat center
            center @color-dark;
          .borderRadius(0px);
          color: transparent;
          text-decoration: none;
          margin: 0;
          &:hover,
          &:focus {
            background-color: @color-primary--darker;
          }
        }
      }
      form.vrtx-big-search {
        input,
        button {
          &:focus {
            outline: auto;
          }
        }
        button {
          &:focus {
            outline-offset: -5px;
          }
        }
      }
      form.vrtx-big-search input[type="text"],
      form.vrtx-big-search input[type="search"] {
        border-right: 1px solid @color-neutral--light;
        font-family: @font-header;
        font-weight: 400;
        .borderRadius(0px) !important;
        width: 320px;
        margin: 0;
      }
    }
  }
}
// Searchview ^

#error #main {
  width: @pagewidth-wide;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  h1 {
    width: auto;
    margin: 0 auto;
  }
  button {
    background-color: @color-primary--dark !important;
    &:hover,
    &:focus {
      .transition(all, 1s, ease-in);
      background-color: @color-primary--darker !important;
    }
  }
}

.left {
  padding-right: 0px;
}

.not-for-ansatte {
  #search.menu-search button,
  #header-menu-search button {
    background-image: url(../images/sprite-nh-magnifier-white.svg);
    background-position: 24px 17px !important;
    background-repeat: no-repeat;
    background-size: inherit;
    background-color: @color-neutral--dark;
    height: 52px;
    width: 70px;
    margin-left: -4px;
    .borderRadius(0px);
    color: transparent;
    input[type="text"] {
      .borderRadius(0px);
    }
  }
  #main form.vrtx-big-search {
    margin-top: 40px;
    button[type="submit"] {
      background: url(../images/sprite-nh-magnifier.svg) no-repeat center center
        @color-dark;
      height: 52px;
      margin-top: 0px;
      padding: 10px 20px;
      .borderRadius(0px);
      &::before,
      &::after {
        border: 0;
        background: none;
        .borderRadius(0px);
        height: 40px;
      }
    }
  }
  .header-search {
    border: 2px solid @color-light;
    padding-top: 6px;
    padding-left: 0px;
    .borderRadius(25px) !important;
    height: 42px;
    width: 42px;
    margin-top: 5px;
    &:focus,
    &:hover {
      border: 2px solid @color-light;
      background-color: @color-neutral--dark;
      box-shadow: 0 0 5px @color-light;
    }
  }
  .header-search-expand {
    background-color: transparent !important;
    color: transparent !important;
    &::after {
      content: "";
      background: #fff;
      display: block;
      width: 0;
      height: 2px;
      position: absolute;
      left: -6px;
      bottom: -10px;
      transition: all 0.3s;
    }
    &:focus-visible::after {
      width: 50px;
    }
  }
  &.header-search-collapsable {
    #header-menu-search-expand {
      background: url(../images/sprite-nh-magnifier-white.svg) no-repeat center;
      background-size: 18px 18px;
      background-position: 10px 8px;
      transform-origin: 19px 18px;
      z-index: 100;
      transition: 0.5s;
      &.close {
        background: url(../images/sprite-nh-exit-white.svg) no-repeat center;
        background-position: 10px 8px;
        //transform: rotate(360deg); //interferes with focus style
      }
    }
    &:not(.header-search-collapsed) {
      .head-menu,
      .header-search-expand {
        float: right;
      }
    }
  }

  .menu-search {
    overflow: hidden;
    height: 0;
    display: flex;
    align-items: center;
    background-color: @color-primary;
    color: @color-neutral--dark;
    input[type="text"] {
      padding: 7px 15px 6px 15px;
      font-family: @font-header;
      font-weight: 400;
      color: @color-neutral--dark;
      .borderRadius(0px);
    }
    #search-string-responsive {
      width: 517px;
      height: 52px;
      border: none;
      .borderRadius(0px);
      color: @color-neutral--dark;
    }
    > form {
      margin: 0 auto;
      width: @pagewidth-wide;
      text-align: center;
      color: @color-neutral--dark;
    }
    input,
    button {
      &:focus {
        outline: auto;
      }
    }
    button {
      &:focus {
        outline-offset: -5px;
      }
    }
  }

  .header-search {
    top: 20px;
    form {
      display: none;
    }
  }

  #vrtx-advanced-search-wrapper {
    margin-top: 40px;
    input,
    button,
    .button {
      &:focus {
        outline: auto;
      }
    }
    button,
    .button {
      &:focus {
        outline-offset: -5px;
      }
    }
  }

  #head-wrapper {
    background: @color-neutral;
    padding-top: 30px;
    #head {
      height: 145px;
      .uio-app-name {
        display: none;
      }
      .head-menu {
        z-index: 12;
        top: -25px;
        right: 0px;
        color: @color-light;
        a {
          color: @color-light;
          .font-size(16);
          font-weight: 400;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
        .vrtx-dropdown-wrapper-inner li a {
          color: @color-dark;
        }
        .language {
          a {
            display: none;
          }
        }
      }
      #header {
        #logo {
          position: absolute;
          margin-top: 0px;
          .font-size(50);
          font-family: @font-header;
          font-weight: 400;
          color: @color-light;
          &:hover,
          &:focus {
            color: @color-primary;
            text-decoration: none;
          }
        }
        #header-description {
          .font-size(20);
          color: @color-light;
          top: 80px;
          line-height: normal;
          position: absolute;
          font-style: italic;
        }
        // uio logo (shown only on large screen)
        .uio-logo {
          @media (min-width: 605px) {
            background: url("../images/uio-logo-no-white.svg") no-repeat center;
            background-size: contain;
            width: 200px;
            height: 50px;
            position: absolute;
            right: 120px;
            top: 21px;
            &:focus {
              outline: auto;
            }
          }
          @media (max-width: 604px) {
            display: none;
          }
        }
      }
    }
  }
}

.tooltip.tooltip-author {
  position: relative;
  height: auto;
  background: @color-light;
  border: @color-primary solid 3px;
  max-width: 314px;
  margin-top: 20px;
  padding: 20px 30px 30px;
  position: absolute;
  z-index: 1;
  margin-left: -70px;
  &::before {
    border-width: 0 17px 17px;
    border-color: @color-primary transparent;
    z-index: 0;
    top: -20px;
    left: 108px;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
  }
  p.tooltip-author__name {
    font-weight: bold;
    color: @color-neutral--dark;
    margin-bottom: 10px;
  }
  p.tooltip-author__meta {
    color: @color-neutral--dark;
  }
}
// /* Tidslinje  */
#main #vrtx-main-content {
  .vrtx-frontpage-box.tidslinje {
    text-align: left;
    padding: 0 0 0 80px;
    &::before {
      z-index: -2;
      position: absolute;
      left: 14px;
      top: 0;
      bottom: 0;
      width: 4px;
      background-color: @color-primary;
    }
    &::after {
      z-index: -1;
      content: "";
      display: block;
      position: absolute;
      visibility: visible;
      top: 33px;
      left: 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 4px solid @color-primary;
      background: @color-background;
    }
    &.i-dag {
      margin-top: 20px;
    }
    &.fortid {
      margin: 0 0 -40px 0;
      padding: 0;
      max-width: 640px;
      width: 100%;
      .vrtx-box-content h2 {
        margin-left: 20px;
      }
      img {
        margin: 0;
      }
      &::before,
      &::after {
        display: none;
      }
      .vrtx-box-content {
        display: flex;
        justify-content: start;
        align-items: center;
      }
    }
    p {
      margin-bottom: 0px;
    }
    .vrtx-box-content {
      cursor: pointer;
      border-top: 2px solid @color-neutral--light;
      padding: 14px 20px 14px 5px;
      &:hover,
      &:focus {
        background-color: @color-neutral--air;
      }
      h2 {
        .font-size(22);
        font-weight: 400;
        margin: 0 0 0 -5px;
        padding-left: 5px;
        cursor: pointer;
        a {
          outline: 0 !important;
          color: inherit !important;
          display: block;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
        &::after {
          position: absolute;
          .link-arrow(url(../images/sprite-right.svg), 30px, 20px, 50%, -13px);
        }
      }
    }

    .periode {
      font-family: @font-header;
      padding-bottom: 5px;
      cursor: pointer;
      display: block;
      color: @color-primary--dark;
      &:hover,
      &:focus {
        text-decoration: none !important;
      }
    }
  }

  .tidslinje:not(.fortid),
  .tidslinje:not(.fortid):before,
  .tidslinje:not(.fortid):after,
  .tidslinje:not(.fortid) *,
  .tidslinje:not(.fortid) *:before,
  .tidslinje:not(.fortid) *:after {
    box-sizing: border-box;
    content: " ";
  }
  .tidslinje {
    .ui-accordion.ui-widget .ui-accordion-content {
      padding-left: 0;
    }
    ul li {
      margin-left: 0;
    }
  }
}

// forsidebokser med bilder og hvit titteltekst brukt på undervisningsopplegg
#main .three-column-tiles .vrtx-box-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 20px;
  flex-direction: row;
  .image-tile {
    cursor: pointer;
    background-size: cover;
    width: 30%;
    margin-bottom: 40px;
    width: 310px;
    background-repeat: no-repeat;
    height: 150px;
    position: relative;
    a {
      font-family: @font-header;
      text-decoration: none;
      display: block !important;
      color: @color-light;
      padding: 0 10px;
      .font-size(30);
      line-height: 1.3;
      position: absolute;
      bottom: 30px;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
    a.antall-artikler {
      .font-size(18);
      font-family: @font-content;
      position: absolute;
      color: @color-dark;
      background: @color-light;
      width: 100%;
      bottom: -25px;
      padding: 10px;
      &.left,
      &.right {
        margin-bottom: 0;
      }
      span.itemCount {
        font-weight: 600;
      }
    }
    &::after {
      .link-arrow(
        url(../images/sprite-right-light.svg),
        32px,
        20px,
        initial,
        5px
      );
      background-size: 80%;
      bottom: 30px;
    }
  }
}

.not-for-ansatte {
  .authors-era-wrapper {
    padding: 40px 20px 100px 20px;
    background-color: @color-neutral--light;
    .era-authors {
      width: @pagewidth-narrow;
      margin: 0 auto;
      border: 4px solid @color-primary;
      padding: 40px 100px 70px 100px;
    }
  }
  .authors-white-wrapper {
    background-color: transparent;
    .vrtx-authors {
      border: none;
      margin: -20px 0 40px 0;
      .author-pretext {
        font-weight: 400px;
        color: @color-dark;
      }
    }
  }
  .authors-wrapper {
    position: relative;
    height: auto;
    min-height: 100% !important;
    display: block;
    margin-bottom: 40px;
    background-color: @color-neutral--light;
    width: calc(100vw);
    margin-left: 50%;
    transform: translateX(-50%);
    .info-box {
      position: relative;
      overflow: auto;
      height: auto;
      min-height: 100% !important;
      display: block;
      width: @pagewidth-wide;
      margin: 0 auto;
      padding: 40px 0 30px 0;
      figure {
        position: relative;
        height: 100%;
        clear: both;
        overflow: auto;
      }
      h2 {
        margin-top: 8px;
      }
    }
    .authors-box {
      display: flex;
      flex-direction: row;
      width: @pagewidth-wide;
      margin: 0 auto;
      .author-photo img {
        margin-top: 50%;
        width: 200px;
      }
      .vrtx-authors {
        float: none !important;
        background: none !important;
        max-width: @pagewidth-narrow;
        margin: 20px auto 40px auto;
        color: @color-primary;
        .font-size(18);
        line-height: 1.6;
        border: none;
        a {
          text-decoration: underline;
          color: @color-neutral;
        }
        .author-pretext {
          .font-size(20);
          font-weight: 400px;
          color: @color-primary--dark;
          display: block;
        }
        .author-names {
          .font-size(26);
          color: @color-dark;
        }
        .author-position {
          color: @color-neutral;
          margin-bottom: 0;
          margin-top: 0;
          .font-size(18);
          font-style: italic;
        }
        .author-description {
          padding-top: 20px;
          font-size: 20px;
          color: @color-neutral--dark;
        }
      }
    }
  }
}

.vrtx-social-components {
  display: none;
}

.pop-menu {
  left: 25%;
  top: 75%;
  display: flex;
  flex-direction: column;
  background: @color-primary--dark;
  position: absolute;
  z-index: 20;
  width: 250px;
  -webkit-animation: pop-menu-in 400ms cubic-bezier(0, 0, 0.1, 1) both;
  animation: pop-menu-in 400ms cubic-bezier(0, 0, 0.1, 1) both;
  .pop-menu__action {
    color: @color-light !important;
    padding: 10px 70px;
    &.pop-menu__action--fb {
      background: transparent url(../images/social-facebook-white.svg) no-repeat
        scroll 20px center;
      background-size: 15% auto;
    }
    &.pop-menu__action--em {
      background: transparent url(../images/social-mailto-white.svg) no-repeat
        scroll 20px center;
      background-size: 15% auto;
    }
    &.pop-menu__action--t {
      background: transparent url(../images/social-twitter-white.svg) no-repeat
        scroll 20px center;
      background-size: 15% auto;
    }
  }
  .pop-menu__title {
    font-family: @font-content;
    position: relative;
    z-index: 40;
    margin: 0;
    padding: 14px 0;
    font-size: 18px;
    line-height: 1.6;
    text-align: center;
    color: @color-light;
    margin: 10px 20px;
    border-bottom: 2px solid @color-light;
  }
  .pop-menu__text {
    resize: both;
    position: relative;
    z-index: 30;
    height: 200px;
    padding: 10px 0;
    outline: 0;
    font-size: 16px;
    color: @color-light;
    background: transparent;
  }
  textarea {
    border: none;
    margin: 10px 30px;
    width: 80%;
    padding: 0px 0px;
    line-height: 1.6;
    &::-moz-selection {
      background: @color-primary--darker;
      color: @color-light;
    }
  }
  button.pop-menu__close {
    background: none !important;
    color: @color-light !important;
    font-weight: normal;
    border-top: 2px solid @color-light;
    margin: 20px 20px 14px 20px;
    border-radius: 0;
    padding-top: 14px;
    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

.quote-share-feedback-wrapper {
  position: relative;
  background-color: @color-neutral--air;
  padding: 20px 0;
  margin: 0px -15px;
  .quote-share-feedback {
    width: @pagewidth-narrow;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .button {
      flex: 1;
      background: no-repeat transparent bottom left !important;
      background-size: auto 30px !important;
      padding: 0 0 0 50px !important;
      height: 30px;
      color: @color-dark !important;
      font-weight: normal !important;
      font-family: inherit;
      text-align: left;
      &.quote {
        background-image: url("../images/quote.svg") !important;
      }
      &.share {
        background-image: url("../images/share.svg") !important;
      }
    }
    a {
      &.feedback {
        flex: 1;
        background: url("../images/feedback.svg") no-repeat 0 5px !important;
        background-size: auto 30px !important;
        padding-left: 50px !important;
        color: @color-dark !important;
        text-decoration: none !important;
        margin-top: 0px;
      }
    }
    .button,
    a.feedback {
      &:hover,
      &:focus {
        text-decoration: underline !important;
      }
    }
  }
}

#vrtx-content {
  #vrtx-main-content {
    table.authortable {
      tr td:nth-child(4),
      tr th:nth-child(4),
      tr td:nth-child(5),
      tr th:nth-child(5) {
        display: none;
      }
      &.minimal {
        border: 0 !important;
        th {
          border-width: 0 0 3px !important;
        }
        td {
          border-width: 0 0 1px;
          font-size: 14px;
        }
      }
      &.zebra tr:nth-child(2n + 1) td,
      table.uio-zebra tr:nth-child(2n + 1) td {
        background: @color-neutral--air none repeat scroll 0 0;
      }
    }
  }
}

// Galleri
.vrtx-image-listing .vrtx-image-entry {
  margin: 0 7px 20px 0;
}
body #main .vrtx-image-listing-include {
  a.toggle-fullscreen.minimized {
    height: 100% !important;
  }
  a:focus {
    outline: none !important;
  }
  .thumbs-next-row,
  .thumbs-prev-row {
    background-color: transparent;
  }
}

html.js.fullscreen-gallery {
  .fullscreen-gallery-topline {
    padding: 22px 55px 20px;
    .uio-app-name a {
      &.uio-seal,
      &.uio-acronym {
        display: none !important;
      }
    }
  }
  .vrtx-image-listing-include-container-description a {
    color: @color-primary !important;
    text-decoration: none !important;
    &:hover,
    &:focus {
      text-decoration: underline !important;
    }
  }
}
// ^ Galleri ^

.vrtx-date-info {
  width: @pagewidth-narrow;
  margin: 0 auto;
  padding-bottom: 20px;
  border-top: none;
}

#main div.tagged-resources {
  width: @pagewidth-narrow;
  float: none;
  margin: 0 auto;
}

#vrtx-article-listing {
  &.not-for-ansatte #total-main {
    width: @pagewidth-narrow;
    margin: 0 auto;
  }
  .vrtx-resources .description.introduction h2 {
    display: none;
  }
}

#vrtx-frontpage.vrtx-frontpage-full-width.total-main #main,
#vrtx-structured-article {
  .grid-container:not(.grid-color-blank):not(.grid-color-grey):not(.grid-color-black):not(.full-width-picture-bg).row,
  #vrtx-change-language-link,
  .vrtx-back,
  .vrtx-context-box,
  #vrtx-frontpage-introduction,
  #vrtx-main-user,
  #main #total-main #vrtx-content {
    width: @pagewidth-narrow;
    position: relative;
    margin: 0 auto;
  }
}

#vrtx-structured-article #vrtx-main-user {
  margin-bottom: 20px;
}

body.globalnav-smaller #globalnav ul li {
  padding: 0;
  a {
    padding: 5px 15px 10px;
    .font-size(20);
  }
}

.not-for-ansatte {
  #globalnav {
    background: none;
    padding: 0 0 15px 0;
    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 0px;
      li {
        margin-left: 0px;
        border: 2px solid transparent;
        padding: 0;
        a {
          padding: 5px 15px 10px;
          display: block;
          color: @color-light;
          font-family: @font-header;
          font-weight: 400;
          position: relative;
          .font-size(20);
          &:after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 100%;
            height: 2px;
            background: #fff;
            .transform(scale3d(0, 1, 1));
            .transition(all, 0.1s);
          }
          &:focus,
          &:hover {
            background: none;
            color: @color-light;
            &:after {
              .transform(scale3d(1, 1, 1));
              -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
            }
          }
        }
      }
      li.vrtx-active-item {
        border: 2px solid @color-light;
        background-color: transparent;
        a {
          background-color: transparent;
          &:hover,
          &:focus {
            background-color: @color-light;
            color: @color-dark;
          }
          &:after {
            .transform(scale3d(1, 1, 1));
          }
        }
      }
    }
  }

  #bottomnav {
    display: none;
    #bottom-menu {
      display: none;
    }
  }
}

#vrtx-frontpage-introduction {
  margin-bottom: 0px;
  width: @pagewidth-narrow;
}

.three-column-feed.feed-image-over .vrtx-feed ul li a.vrtx-image:first-child {
  width: auto;
}

#vrtx-article-listing #main {
  .vrtx-resources .vrtx-resource {
    border-bottom: none;
    padding-bottom: 50px;
    .published-date,
    .vrtx-read-more {
      display: none;
    }
  }
}

.uio-main ul li {
  &:before {
    content: "\25CF";
    color: @color-primary--dark;
    margin-left: -2.2ex;
    top: 0.13em;
  }
}

#vrtx-article-listing #main,
#main,
#vrtx-main-content:not(.vrtx-hide-additional-content-true):not(.vrtx-empty-additional-content) {
  width: 100%;
  margin: 0;
  .introduction-div,
  .vrtx-introduction-big,
  .vrtx-frontpage-box.introduction .vrtx-box-content,
  .vrtx-organizational-unit-introduction,
  .vrtx-introduction {
    width: @pagewidth-narrow;
    font-family: @font-content;
    font-weight: 400;
    .font-size(22);
    line-height: 1.5;
    margin: 0 auto;
    padding: 0 0 40px 0;
    h2 {
      margin-top: 20px;
    }
  }
  #right-main {
    width: @pagewidth-narrow;
    margin: 0 auto;
    float: none;
  }
  #left-main {
    display: none;
    margin-top: 70px;
    .vrtx-breadcrumb-menu {
      li.vrtx-child {
        > span,
        > a {
          background-color: @color-light;
          border-top: 2px solid @color-neutral--light;
          &::before {
            content: "";
          }
          &.vrtx-marked {
            background-color: @color-primary;
          }
        }
      }
    }
  }
  .vrtx-feed li,
  .vrtx-resources .vrtx-resource {
    cursor: pointer;
    position: relative;
    margin-bottom: 0;
    display: block;
    padding: 40px 40px 40px 0;
    border-top: 3px solid @color-neutral--light;
    border-bottom: 0;
    .vrtx-title {
      a.vrtx-title {
        color: @color-dark;
        text-decoration: none;
        &:hover,
        &:focus {
          color: @color-dark;
          text-decoration: underline;
        }
      }
      .source-date {
        color: @color-contrast;
      }
    }
    .description::after,
    .introduction::after {
      content: initial;
      .link-arrow(url(../images/sprite-right.svg), 30px, 20px, 50%, -5px);
    }
    &:hover,
    &focus {
      background: @color-neutral--air;
      border-top: 3px solid @color-neutral--light;
    }
    &:before {
      content: "";
    }
  }
  .three-column-feed .vrtx-feed li {
    padding: 40px;
  }

  .vrtx-paging-feed-wrapper .vrtx-paging-wrapper > a.vrtx-page-number {
    &:hover,
    &:focus {
      background: @color-primary;
      color: @color-neutral--dark;
    }
  }
}

#main .tag-filter .vrtx-feed ul.items li {
  margin-left: 0;
}

#vrtx-collection #main .vrtx-resources .vrtx-resource {
  border-bottom: 0;
}

#main .vrtx-paging-feed-wrapper {
  margin: 0;
  padding: 40px 0 20px 0;
}

.grid-container {
  background: transparent;
}

.vrtx-frontpage-box:not(.featured-item):not(.half-box-right):not(.half-box-left):not(.third-box-left):not(.third-box-middle):not(.third-box-right) {
  width: @pagewidth-narrow;
  margin: 0 auto;
}

#main #articleListing\.searchComponent {
  width: @pagewidth-narrow;
  margin: 0 auto;
  padding: 0;
  &.vrtx-resources.vrtx-two-columns {
    width: @pagewidth-wide;
    margin-left: -25%;
    .vrtx-resource {
      padding: 40px;
      border: none;
      &:hover,
      &:focus {
        border: none;
      }
    }
  }
}

#main #vrtx-main-content {
  .grid-container .vrtx-frontpage-box {
    &.half-box-right,
    &.half-box-left {
      margin-bottom: 40px;
    }
    &.vrtx-main-content-last {
      padding-bottom: 40px;
    }
    &.tidslinje.vrtx-main-content-last {
      padding-bottom: 40px;
      .vrtx-box-content h2::after {
        margin-top: -33px;
      }
    }
  }
  .vrtx-feed,
  .vrtx-feed.with-images {
    .item-description::after {
      background-image: url(../images/sprite-right.svg);
      background-repeat: no-repeat;
      content: "";
      height: 30px;
      width: 20px;
      position: absolute;
      right: 0;
      top: 50%;
      margin-top: -10px;
    }
  }
  .vrtx-event-component a.vrtx-event-component-title,
  .vrtx-feed ul li a.item-title,
  .vrtx-recent-comments ul li a.comments-title {
    text-decoration: none;
    font-family: @font-header;
    font-weight: 400;
    font-size: 2.4rem;
    color: @color-dark;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

table th {
  font-weight: bold;
}

blockquote {
  color: @color-neutral;
  display: block !important;
  font-family: @font-header;
  font-weight: 400;
  font-size: 1.231em;
  font-style: italic;
  line-height: 1.563em;
  margin: 20px 50px;
  padding: 0 0 0 15px;
  border-left: 3px solid @color-neutral--light;
}

#main .vrtx-frontpage-box.wide-box {
  .vrtx-box-content {
    .vrtx-list-articles {
      line-height: 1.4;
      padding: 20px;
      .font-size(24);
      font-family: @font-header;
      font-weight: 400;
      color: @color-header;
      ul {
        margin-top: 10px;
        li {
          margin-left: 0px;
          border-bottom: 2px solid @color-neutral--light;
          border-top: 2px solid transparent;
          line-height: 1.2;
          padding: 3px 40px 10px 0px;
          margin-top: -7px;
          &::before {
            content: "";
          }
          &:hover,
          &focus {
            background: @color-light;
            border-top: 2px solid @color-neutral--light;
          }
          a {
            .font-size(18);
            text-decoration: none;
            font-family: @font-content;
            color: @color-neutral--dark;
            &::after {
              .link-arrow(
                url(../images/sprite-arrow-right.svg),
                14px,
                16px,
                50%,
                -7px
              );
            }
          }
        }
      }
    }
  }
}

#main {
  .vrtx-frontpage-box {
    &.grey-box,
    &.grey-box-light,
    &.grey-clip-box {
      background-color: @color-neutral--light;
    }
  }
  .grid-container {
    padding: 0;
    background: transparent;
    &.no-margin-bottom {
      margin-bottom: 0px;
    }
    &.grid-color-grey {
      width: 100%;
      margin-top: 0px;
      background-color: @color-neutral--light;
      .vrtx-frontpage-box.row-color-grey {
        width: @pagewidth-narrow;
        margin: 0 auto;
        padding: 40px 0;
        h2::after {
          .header-line(@color-primary, 7px, 40px);
        }
      }
    }
    &.grid-color-blank {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 60px;
      background-color: transparent;
      .row {
        width: @pagewidth-wide;
      }
      .vrtx-frontpage-box.row-color-blank {
        padding-top: 40px;
        width: 100%;
      }
    }
    .link-box {
      padding: 20px;
      .vrtx-box-content {
        padding-left: 0;
        a {
          width: 100%;
          border-bottom: 2px solid @color-primary--dark;
          padding: 10px 0;
          margin-bottom: 0;
          margin-right: -5px;
          display: block;
          text-decoration: none;
          color: @color-primary--dark;
          &:hover,
          &:focus {
            background: @color-neutral--air;
            border-top: 2px solid @color-primary--dark;
            margin-top: -2px;
          }
          &::after {
            .link-arrow(
              url(../images/sprite-right-dark.svg),
              20px,
              20px,
              initial,
              5px
            );
            background-size: 60%;
          }
        }
      }
    }
  }
}

#main ol,
#main ul.content-list {
  max-width: @pagewidth-narrow;
  margin: 40px auto;
}

#main {
  .vrtx-paging-feed-wrapper {
    border-top: 4px solid @color-neutral--light;
  }
  div.vrtx-feed-link a {
    background: url("../images/rss.svg") no-repeat center left;
    background-size: 40px 40px;
  }
}

#vrtx-tags {
  display: block;
  margin-bottom: 20px;
  padding: 20px 0;
  ul > li::before {
    content: "";
  }
}

.vrtx-authors + .vrtx-date-info,
.vrtx-tags + .vrtx-authors,
.vrtx-tags + .vrtx-date-info,
.vrtx-tags {
  margin-left: auto;
  margin-right: auto;
  width: @pagewidth-narrow;
}

.vrtx-tags-scope-up {
  display: none;
}

a.vrtx-more,
div.vrtx-more a,
a.all-messages,
a.all-comments,
.footer > .vrtx-search-scope a,
a.more-url {
  &::before {
    display: none;
  }
}

#vrtx-frontpage
  #main
  .vrtx-frontpage-box.full-width-picture.content-over-image
  .vrtx-box-content
  a.vrtx-more,
#main a.vrtx-more,
#main div.vrtx-more a,
a.all-messages,
a.all-comments,
.footer > .vrtx-search-scope a,
a.more-url {
  text-decoration: underline;
  display: unset;
  font-weight: 400;
  &::after {
    background: url("../images/sprite-arrow-right-dark.svg") no-repeat 11px 4px !important;
    background-size: 100% auto;
    display: inline-block;
    content: " ";
    width: 1.5em;
    height: 1.1em;
    position: relative;
    top: 0.3ex;
  }
  &:hover::after,
  &:focus::after {
    background: url("../images/sprite-arrow-right-hover.svg") no-repeat 11px 4px !important;
    background-size: 100% auto;
  }
  &.full-line {
    text-decoration: none;
    display: block;
    border-bottom: 2px solid @color-primary--dark;
    &::after {
      float: right;
    }
    &:hover,
    &:focus {
      border-bottom: 2px solid @color-neutral--dark;
    }
  }
}

#vrtx-structured-article #main .vrtx-back {
  margin-bottom: 0;
}

#vrtx-frontpage.vrtx-frontpage-full-width.total-main #main .vrtx-back,
#main .vrtx-back {
  margin: 7px auto -35px auto;
  a {
    font-family: @font-header;
    .font-size(16);
    padding: 5px 0;
    font-weight: 400;
    &::before {
      background: url("../images/sprite-left-dark.svg") no-repeat 0 -1px;
      background-size: auto 100%;
      vertical-align: middle;
      height: 15px;
      content: " ";
    }
  }
}

body #main #vrtx-main-content .feed-podcast .items li {
  cursor: default;
  a.item-title {
    background: url(../images/play-button.svg) left no-repeat;
    background-size: 26px;
    padding-left: 36px;
    font-size: 20px;
    font-size: 2rem;
    line-height: 2.9rem;
    text-decoration: none;
    &:focus,
    &:hover {
      color: @color-neutral--dark;
    }
  }
  .item-description::after {
    display: none;
  }
  .published-date {
    font-family: @font-header;
  }
}

.ui-accordion.ui-widget {
  .transition(all, 0.5s, ease-in);
  .ui-accordion-header {
    color: @color-dark;
    padding: 10px 0 10px 0px;
    .ui-icon {
      background: transparent url("../images/sprite-down-dark.svg") no-repeat;
      background-size: 80%;
      float: right;
      height: 25px;
      width: 25px;
      background-position-y: 10px;
      margin-right: 0px;
    }
    &.ui-accordion-header-active .ui-icon {
      background-image: url("../images/sprite-up-dark.svg");
      background-size: 80%;
    }
    &.pil-venstre {
      border: none;
      .ui-icon {
        float: left;
        margin-left: 0px;
        margin-right: 10px;
      }
    }
  }
}

//kilde
table#kildeinfo-tabell {
  border: none;
  &.vertical tbody th[scope="row"] {
    border-width: 0;
    color: @color-dark;
    padding: 10px 70px 5px 0;
    width: 30%;
  }
  td {
    border-width: 0;
  }
}

//Kilde accordion
body #main .kilde-wrapper {
  background: @color-light;
  margin-bottom: -40px;
  padding-bottom: 40px;
  .kilde {
    padding: 20px 0;
    width: @pagewidth-narrow;
    margin: 0 auto;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      background-color: @color-light;
    }
    &::after {
      left: 100%;
      background: @color-light;
    }
    .kildetabell {
      border-color: @color-neutral--grey;
      th,
      td {
        border-color: @color-neutral--grey;
      }
      th {
        color: @color-neutral--dark;
      }
      -webkit-animation: table-expand 1s ease-in-out both;
      animation: table-expand 1s ease-in-out both;
    }
    table.mer-om {
      display: none;
    }
    h3.mer-om {
      cursor: default;
      .font-size(18);
      font-family: @font-content;
      color: @color-primary--dark;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 2px solid @color-neutral--light;
      &::before {
        content: " ";
        background: url("../images/sprite-down-dark.svg") no-repeat;
        background-size: 65%;
        height: 30px;
        width: 30px;
        background-position-y: 100%;
        display: inline-block;
      }
      &.active {
        cursor: default;
        &::before {
          content: " ";
          background-image: url("../images/sprite-up-dark.svg");
        }
      }
      &:hover,
      &:focus {
        text-decoration: underline;
        color: @color-dark;
      }
    }

    h3.accordion {
      .font-size(22);
      color: @color-dark;
      &.ui-accordion-header {
        color: @color-header;
        padding-top: 20px;
        padding-bottom: 7px;
        padding-left: 0;
        border-bottom: 2px solid @color-primary--dark;
        .ui-icon {
          background: transparent url("../images/sprite-down-dark.svg")
            no-repeat 100% 80%;
          background-size: 80%;
          height: 30px;
          width: 30px;
          margin-right: 0px;
        }
      }
      &.ui-accordion-header-active {
        .transition(all, 3s, ease-in);
        .ui-icon {
          background-image: url("../images/sprite-up-dark.svg");
        }
      }
      &:first-child {
        border-top: none;
      }
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    .ui-accordion.ui-widget .ui-accordion-content {
      padding-left: 0;
    }

    .vrtx-image-listing-include {
      ul li a {
        width: 80px;
        height: 60px;
      }
      .thumbs-prev-row,
      .thumbs-next-row {
        height: 60px;
      }
      .thumbs-next-row {
        margin: -95px 0 0 auto;
      }
      .vrtx-image-listing-include-container-description
        + .vrtx-image-listing-include-thumbs {
        margin-top: 50px !important;
      }
    }
  }
}

// CUstom radio buttons
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: @color-dark;
  font-family: @font-header;
  &:hover,
  &:focus {
    color: @color-primary--dark;
  }
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -3px;
    width: 18px;
    height: 18px;
    border: 3px solid @color-dark;
    border-radius: 100%;
    background: @color-light;
  }
  &:hover:before,
  &:focus:before {
    border: 3px solid @color-primary--dark;
  }
}
[type="radio"]:checked + label:before {
  border: 3px solid @color-primary--dark;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: @color-primary--dark;
  position: absolute;
  top: 3px;
  left: 6px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
[type="radio"]:focus-visible + label {
  outline: 2px solid;
  outline-offset: 2px;
}

#main .filter-result {
  margin-bottom: 15px;
  .vrtx-resources .vrtx-resource {
    border-bottom: none;
    &:last-child {
      padding-bottom: 40px;
    }
    .publish-date,
    .vrtx-read-more {
      display: none;
    }
  }
}

.not-for-ansatte {
  #vrtx-related-content {
    width: 100%;
    background-color: @color-neutral--grey;
    padding-bottom: 40px;
    .les-mer,
    #les-mer,
    #anbefalt,
    #eksterne-lenker {
      width: @pagewidth-narrow;
      margin: 0 auto;
      padding: 20px 0;
      position: relative;
      text-decoration: none;
      h2::after {
        .header-line(@color-primary, 7px, 40px);
      }
    }
    #les-mer,
    .les-mer {
      h2 {
        margin-top: 0;
        padding-top: 40px;
      }
      a {
        width: 100%;
        padding: 10px 0;
        margin-right: -5px;
        margin-top: -20px;
        text-decoration: none;
        color: @color-dark;
        display: block;
        border-bottom: 2px solid @color-primary--dark;
        &::after {
          .link-arrow(
            url(../images/sprite-right-dark.svg),
            20px,
            20px,
            initial,
            5px
          );
          background-size: 60%;
        }
        &:hover,
        &:focus {
          background: @color-neutral--air;
          border-top: 2px solid @color-primary--dark;
          margin-top: -22px;
          text-decoration: none;
        }
      }
    }
    #anbefalt {
      a {
        padding: 10px 0 10px 0;
        color: @color-primary--dark;
        margin-top: 20px;
        margin-bottom: 20px;
        &:hover,
        &:focus {
          text-decoration: underline;
        }
        &:focus {
          outline: auto;
        }
      }
    }
    #eksterne-lenker {
      a {
        border: none;
        color: @color-primary--dark;
        &:hover,
        &:focus {
          background-color: transparent;
          text-decoration: underline;
        }
      }
    }
  }
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }

  //footer
  #footer-wrapper {
    position: relative;
    padding: 40px 0 80px;
    background-color: @color-neutral;
    border-bottom: 110px solid #000; // space for uio logo
    *,
    a {
      color: @color-primary;
      .font-size(18);
      &:hover,
      &:focus {
        color: @color-light;
      }
    }
    #footers {
      width: @pagewidth-narrow;
      position: static; // use wrapper as relative
      // uio-logo
      .uio-logo {
        background: url("../images/uio-logo-no-white.svg") no-repeat left center;
        background-size: contain;
        position: absolute;
        bottom: -85px; // placed on wrapper bottom border
        height: 60px;
        width: 250px;
        &:focus {
          outline: auto;
        }
      }
      .footer-title {
        .font-size(30);
        color: @color-light;
        font-family: @font-content;
        font-weight: 400;
        line-height: 1.4;
        font-style: italic;
        position: relative;
        margin-top: 0;
        padding-bottom: 20px;
        margin-bottom: 20px !important;
      }
      .col-3-5 {
        width: 100%;
        padding: 22px 0;
        .social-components {
          a {
            color: transparent;
            margin-right: 20px;
            padding: 45px 0;
            width: 45px;
            height: 45px;
            display: inline-block;
            &:hover,
            &:focus {
              color: transparent;
              box-shadow: none;
            }
            &.facebook {
              background: transparent url(../images/social-facebook-white.svg)
                no-repeat scroll left center;
              background-size: 100% auto;
              &:hover,
              &:focus {
                background: transparent
                  url("../images/social-facebook-grey.svg") no-repeat scroll
                  left center;
                background-size: 100% auto;
              }
            }
            &.mailto {
              background: transparent url(../images/social-mailto-white.svg)
                no-repeat scroll left center;
              background-size: 100% auto;
              &:hover,
              &:focus {
                background: transparent url("../images/social-mailto-grey.svg")
                  no-repeat scroll left center;
                background-size: 100% auto;
              }
            }
          }
        }
      }
      .col-2-5 {
        width: 100%;
        &.responsible-login .vrtx-login-manage-component {
          padding-top: 0px;
        }
      }
      .footer-title:after {
        .header-line(@color-primary, 15px, 0);
      }
      .vrtx-login-manage-component::before {
        .header-line(@color-primary, 7px, 20px);
      }
      .footer-title:after {
        bottom: 0;
      }
      .footer-links li {
        margin-left: 0;
        &::before {
          content: "";
        }
      }
      .responsible-login {
        float: none;
        .content {
          margin-left: 0;
          .menu-label,
          p {
            display: none;
          }
          .vrtx-login-manage-wrapper {
            a {
              color: #000;
              text-decoration: none;
              &:hover,
              &:focus {
                text-decoration: underline;
              }
            }
          }
          .vrtx-dropdown-wrapper {
            top: auto;
            margin-top: 5px;
          }
          .vrtx-dropdown-link {
            &:focus-visible {
              outline: auto;
              outline-offset: 5px;
            }
          }
        }
      }
    }
  }
}

.uio-app-name.survey-logo-wrapper {
  .uio-acronym {
    display: none;
  }
}
.ui-dialog .ui-dialog-content .vrtx-dialog-content {
  .survey-open {
    background-color: @color-primary--dark;
    color: @color-light;

    &:hover,
    &:focus {
      background-color: @color-primary--darker;
    }
  }
  .survey-minimize,
  .survey-close {
    color: @color-primary--dark;
  }
}

.survey-minimized {
  background-color: @color-primary--dark;
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  #main {
    padding: 0 15px 15px;
  }
  #error #main {
    width: 100%;
  }
  #vrtx-frontpage.vrtx-frontpage-full-width.total-main #main .vrtx-back,
  #main .vrtx-back {
    margin: 0;
    a {
      .font-size(14);
      padding: 0;
      &:before {
        padding: 0;
      }
    }
  }

  #vrtx-structured-article {
    #main #total-main #vrtx-content,
    #vrtx-main-user,
    #main .vrtx-introduction,
    .kilde-wrapper .kilde,
    #vrtx-main-content:not(.vrtx-hide-additional-content-true):not(.vrtx-empty-additional-content)
      .vrtx-introduction {
      width: 100%;
    }
  }

  #vrtx-article-listing.not-for-ansatte {
    #main,
    #total-main,
    .vrtx-introduction {
      width: 100%;
      .vrtx-introduction,
      #articleListing\.searchComponent {
        width: 100%;
        &.vrtx-resources.vrtx-two-columns {
          margin-left: 0;
          .vrtx-resource {
            padding: 10px 30px 30px 10px;
          }
        }
      }
    }
  }

  #vrtx-frontpage #main {
    .grid-container .row {
      padding: 0 10px;
    }
    .grid-container:not(.row-last-colored)
      + .grid-container:not(.row-first-colored) {
      border-top: none;
    }
  }

  #main div.vrtx-more a::after {
    background: url(../images/sprite-arrow-right-dark.svg) no-repeat 11px 8px !important;
  }

  #vrtx-frontpage.vrtx-frontpage-full-width.total-main,
  #vrtx-structured-article,
  #main {
    width: 100%;
    #main,
    #right-main,
    h1 {
      width: 100%;
      .grid-container:not(.grid-color-blank):not(.grid-color-grey):not(.grid-color-black):not(.full-width-picture-bg).row,
      h1,
      #vrtx-frontpage-introduction,
      .vrtx-introduction,
      #vrtx-main-user,
      #total-main #vrtx-content,
      #right-main,
      .kilde {
        width: 100%;
      }
    }
  }

  #vrtx-frontpage #main h1 {
    margin-top: 30px;
  }

  #main form.vrtx-big-search button[type="submit"] {
    background-image: url(../images/sprite.svg);
    background-position: -786px 15px;
    background-repeat: no-repeat;
    background-size: inherit;
    background-color: @color-neutral--dark !important;
    height: 50px;
    width: 50px;
    margin-left: -4px;
    .borderRadius(0px);
    color: transparent;
    input[type="text"],
    input[type="search"] {
      height: 50px !important;
      .borderRadius(0px);
    }
  }

  #vrtx-searchview {
    #bottomnav {
      display: none;
    }
    #main #total-main {
      form.vrtx-big-search input[type="text"],
      form.vrtx-big-search input[type="search"] {
        width: 100%;
        margin-bottom: 40px;
      }
      #vrtx-content,
      #vrtx-main-content {
        width: 100%;
        button.searchsubmit {
          background: url(../images/sprite-nh-magnifier-white.svg) no-repeat
            center @color-dark;
          color: transparent;
          width: 60px;
          height: 56px;
          border-radius: unset;
        }
        .vrtx-paging-feed-wrapper {
          border-top: none;
        }
      }
    }
  }

  .quote-share-feedback-wrapper {
    .quote-share-feedback {
      .button {
        flex: 1;
      }
      a {
        &.feedback {
          flex: 2;
          margin-top: 4px;
        }
      }
    }
  }

  .pop-menu {
    top: auto;
    bottom: 15px;
  }

  .not-for-ansatte {
    html,
    body,
    p {
      .font-size(16);
    }
    h1 {
      .font-size(28);
      margin-top: 20px;
      margin-bottom: 0px;
    }
    h2 {
      .font-size(24);
    }
    h3 {
      .font-size(20);
    }
    h4 {
      .font-size(18);
    }

    #nav-offcanvas {
      .nav-seperator {
        height: auto;
      }
      #lang-link {
        display: none;
      }
      ul li a {
        font-family: @font-header;
        font-weight: 400;
      }
      li.vrtx-parent li.vrtx-child:before {
        margin-left: 2.3em;
        top: 1.4em;
        font-size: 0.5em;
      }
    }
    #head-wrapper {
      background: none @color-neutral;
      height: 115px;
      padding-top: 5px;
      // uio app-line. NOT IN USE
      .uio-app-line-top {
        height: 44px;
      }
      .uio-app-line-top .uio-logo {
        a,
        a:hover,
        a:focus {
          background-image: none, url(../images/sprite.svg);
          background-position: 0px -300px;
          background-size: auto;
          height: 15px;
        }
        a:hover,
        a:focus {
          border-bottom: 2px solid @color-primary--dark;
        }
      }
      #head {
        height: 60px;
        #header {
          #logo {
            .font-size(30);
            margin-left: 14px;
          }
          #header-description {
            display: none;
            padding-left: 15px;
          }
        }
      }
    }

    #menu-wrapper {
      background: @color-neutral none repeat scroll 0 0;
      .menu {
        background: @color-neutral none repeat scroll 0 0;
        li {
          background: @color-neutral none repeat scroll 0 0;
          width: 100%;
          a {
            display: unset;
            font-family: @font-header;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            padding-left: 15px;
            &.toggle-navigation {
              .icon {
                background: url(../images/hamburgermeny.svg) no-repeat 0 0;
                height: 20px;
                width: 20px;
                margin-left: 0;
                margin-bottom: 2px;
              }
              .text {
                padding-left: 10px;
              }
            }
          }
          #toggle-search {
            border: 2px solid @color-light;
            .borderRadius(25px) !important;
            float: right;
            color: transparent;
            overflow: hidden;
            width: 42px;
            height: 42px;
            top: 60px;
            right: 20px;
            position: absolute;
            .icon {
              background: url(../images/sprite-nh-magnifier-white.svg) no-repeat;
              background-size: 18px 18px;
              background-position: 0px 0px;
              height: 42px;
              width: 42px;
              top: 10px;
              right: -15px;
              position: absolute;
              transform-origin: 9px 9px;
              transition: 1s;
            }
          }
          &.active {
            background: @color-primary none repeat scroll 0 0;
            #toggle-search .icon {
              //background: none,
              //  url(../images/sprite-nh-exit-white.svg) no-repeat;
              background-position: 0px 0px;
              //transform: rotate(360deg); //interferes with focus style
            }
          }
        }
      }
    }

    .menu-search,
    #search.menu-search {
      height: 0px;
      padding: 53px 15px 52px 15px;
      margin-bottom: -105px;

      margin-top: 0px;
      form {
        padding-bottom: 0px;
      }
      input[type="text"] {
        border-right-width: 7em !important;
        padding-right: 0px;
      }
      button {
        .transition(all, 0.5s, ease-in);
        background-position: -786px 18px;
        height: 52px;
      }
      button[type="submit"] {
        &::before,
        &::after {
          border: 0;
          background: none;
          .borderRadius(0px);
        }
      }
      #search-string-responsive {
        width: 90%;
      }
    }

    #main .three-column-tiles {
      margin-top: -40px;
      .vrtx-box-content .image-tile {
        width: 100%;
        a {
          .font-size(22);
          &.antall-artikler {
            .font-size(16);
          }
        }
      }
    }

    .authors-wrapper {
      width: 100%;
      margin-left: 0;
      transform: none;
      padding-bottom: 40px;
      .info-box {
        width: 100%;
        padding: 40px 20px 30px 20px;
        overflow: auto;
      }
      .authors-box {
        flex-direction: column;
        width: 100%;
        .author-photo {
          align-self: center;
          img {
            margin: 40px 0 0;
            position: relative;
          }
        }
        .vrtx-authors {
          padding: 20px;
          margin: 0;
        }
      }
    }

    .authors-era-wrapper .era-authors {
      width: 100%;
      padding: 0 20px 40px 20px;
    }

    #bottomnav {
      display: none;
    }

    .quote-share-feedback-wrapper .quote-share-feedback {
      padding: 0 20px;
      width: 100%;
      .font-size(14);
      .quote,
      .share {
        margin-top: auto;
        margin-bottom: auto;
        .font-size(16);
      }
      .button {
        padding-left: 40px !important;
        background-size: auto 20px !important;
      }
      a.feedback {
        background-position-y: 50% !important;
        padding-left: 40px !important;
        background-size: auto 20px !important;
      }
    }
    #vrtx-related-content {
      padding: 0 14px;
      margin: 0;
      #les-mer,
      #anbefalt,
      .les-mer {
        width: 100%;
      }
    }
    #footer-wrapper {
      padding-bottom: 40px !important;
      #footers {
        width: 100%;
        padding-left: 15px;
      }
    }
  }

  .js-ready.csstransforms.csstransitions .grid-container:nth-child(2) {
    margin-top: 0px;
  }
  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }
  .kilde-wrapper .kilde .kildetabell {
    margin-left: 0;
    border-left: 1px solid @color-neutral--grey;
    border-top: 1px solid @color-neutral--grey;
  }
  .kilde-wrapper {
    width: 110%;
    margin: 0 -20px;
    padding: 0 20px;
  }

  table#kildeinfo-tabell.vertical {
    tbody th[scope="row"] {
      padding: 0 5px 0 0;
    }
    td {
      padding: 0 0 5px 0;
    }
  }
  #vrtx-article-listing #main #right-main,
  #main #right-main,
  #vrtx-main-content:not(.vrtx-hide-additional-content-true):not(.vrtx-empty-additional-content)
    #right-main {
    width: 100%;
  }

  .vrtx-date-info {
    width: 100%;
    margin: 0 auto;
    .font-size(16);
  }
}

@media print {
  h1 {
    .font-size(40);
  }
  .quote-share-feedback-wrapper {
    display: none;
  }

  .not-for-ansatte #head-wrapper {
    #head #header {
      width: @pagewidth-narrow;
      margin: 0 auto;
      #logo {
        display: none;
      }
    }
  }

  #vrtx-frontpage.vrtx-frontpage-full-width.total-main,
  #vrtx-structured-article,
  #main {
    width: @pagewidth-narrow;
    #main,
    #right-main,
    h1 {
      width: @pagewidth-narrow;
      .grid-container:not(.grid-color-blank):not(.grid-color-grey):not(.grid-color-black):not(.full-width-picture-bg).row,
      h1,
      #vrtx-frontpage-introduction,
      .vrtx-introduction,
      #vrtx-main-user,
      #total-main #vrtx-content,
      #right-main,
      .kilde {
        width: @pagewidth-narrow;
      }
    }
  }
  #vrtx-article-listing.not-for-ansatte #main #total-main {
    width: @pagewidth-narrow;
    .vrtx-introduction,
    .vrtx-introduction,
    #articleListing\.searchComponent {
      width: @pagewidth-narrow;
    }
  }

  .vrtx-frontpage-box.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
    display: none;
  }

  #main #total-main #vrtx-main-content {
    width: 100% !important;
  }
  .wide-box,
  .grid-color-grey {
    display: none;
  }
  .kilde {
    h3.mer-om {
      display: none;
    }
    table.mer-om {
      display: block;
    }
  }
  .not-for-ansatte #vrtx-related-content {
    display: none;
  }
}
